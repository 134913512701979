.language-popup {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    border-radius: 5px;
    width: 95vw;
    max-width: 800px;
}

.language-popup__item {}

.language-popup__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.7;
}

.language-popup__close:hover {
    opacity: 1;
}
