.language-popup-item {
    padding: 20px;
    cursor: pointer;
}

.language-popup-item:hover {
    background-color: #f3f3f3;
}

.language-popup-item_active {
    background-color: #cccccc;
    cursor: default;
}

.language-popup-item_active:hover {
    background-color: #cccccc;
}
